import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import { Container, Section } from "../components/utils"
import SEO from "../components/seo"
import styled from "styled-components"

const ExtraPageTemplate = ({
  data: {
    page: { title, content },
  },
}) => {
  return (
    <Layout>
      <SEO title={title} />
      <Section padding="small">
        <Container>
          <h1>{title}</h1>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        </Container>
      </Section>
    </Layout>
  )
}
export default ExtraPageTemplate

export const query = graphql`
  query($slug: String!) {
    page: datoCmsExtraPage(slug: { eq: $slug }) {
      title
      content
    }
  }
`
